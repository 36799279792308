import React, { useState } from "react"
import { connect } from "react-redux"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"

import { lingua_it } from "../i18n/it-IT"
import { lingua_en } from "../i18n/en-GB"
import { lingua_es } from "../i18n/es-ES"
import { lingua_ca } from "../i18n/es-CA"

import Seo from "../components/seo"

const AboutUs = props => {
  const { data, lang } = props

  let lingua = {}

  switch (lang) {
    case "it-IT":
      lingua = lingua_it
      break
    case "es-CA":
      lingua = lingua_ca
      break
    case "es-ES":
      lingua = lingua_es
      break
    case "en-GB":
      lingua = lingua_en
      break
    default:
      lingua = lingua_it
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  return (
    <div>
      <Seo
        title={lingua.sito.titolo}
        keywords={[
          `3D Shirt`,
          `configuratore 3d per camicie`,
          `camicie su misura`,
        ]}
        description={lingua.sito.sottotitolo}
      />
      <section className="section" style={{ background: "#f0f5f6" }}>
        <div className="container">
          <h1 className="title is-size-3-mobile is-2 is-spaced">
            {lingua.footer.about}
          </h1>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content is-medium">
                <p className="has-text-weight-bold">
                  {lingua.sito.chisiamo.sotto1}
                </p>
                <p>
                  <span className="has-text-weight-semibold">
                    {lingua.sito.chisiamo.p1}
                  </span>
                  &nbsp;
                  {lingua.sito.chisiamo.p2}
                </p>

                <p>
                  <Img fluid={data.chisiamo.childImageSharp.fluid} />
                </p>

                <p>{lingua.sito.chisiamo.p3}</p>
                <p>{lingua.sito.chisiamo.p4}</p>
                <p>{lingua.sito.chisiamo.p5}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  }
}

export default connect(mapStateToProps)(AboutUs)

export const query = graphql`
  query {
    chisiamo: file(relativePath: { regex: "/sito/chisiamo.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
